

import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../App";
import Upload from "./upload";
import Grid from "./Grid";
import Filter from "./filter";
import Swipe from "./swiping";
import Measure from "./measure";
import Inspect from "./inspect.js";
import Downloads from "./download2";
import GIF from "./gif";
import PixelFilter from "./PixelFilter";
import Survey from "../../projects/Survey.js";
import MapCreation from "./MapCreation.js";
import HOST from "../../host";
import "./tools.css";
import SurveyForm from "../../Pages/SurveyForm.js";
import L from "leaflet";
import SphericalController from "../sphericalCont.js";
import { isMobile } from 'react-device-detect';
import GeoTool from "./GeoTool.js";
import { logToServer } from "../../logger.js";
// import MapboxVectorLayer from "../../Pages/3dMapPages/MapboxVectorLayer.js";
import SLD from "./SLD.js"
import PixelCalc from "./RasCalc.js";
// import MapBox from "./MapBox.js";
import Extract from "./BuildingExt.js";
import VectorA from "./Vector_A.js";
import Clipper from "./ClipRaster.js";
import GeoRefernce from "./GeoReference.js";
import VectorUtils from "./Vector_Tools.js";
import VectorSplit from "./VectorSplit.js";
import Conversion from "./Conversion.js";
import Help from "../../Authforms/HelpForm.js";
import files from "../../static.js";
import Modal from 'react-modal';
// import Documentation from './Documentation.js';
 
function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue(value => value + 1);
}

function Tools({ toggleBuildings, toggleElevationProfile }) {
  const [showModal, setShowModal] = useState(false);
  const [markedLat, setMarkedLat] = useState(null); 
  const [markedLng, setMarkedLng] = useState(null); 
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
    console.log("userinfo", userInfo);
  };

  const {
    userInfo,
    tools,
    Canvas,
    setUserInfo,
    SetLogout,
    UsedLayers,
    vis,
    setVis,
    userProjects,
    organizationProjects,
    setOrganizationProjects,
    setUserProjects,
    getCsrfToken,
    map,
    customMarker,
    threeD,
    set3d, mapBox,
    SetMap, setMapData,
    SetuTab,
    SetMapBox
  } = useContext(GlobalContext);

  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();

  const logout = async () => {
    const result = window.confirm('Are you sure to logout?');
    if (!result) {
      return;
    }
    await fetch(`${HOST}/logout`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': await getCsrfToken(),
      },
      body: JSON.stringify({}),
    });
    if (!window.location.pathname.startsWith("/MapBox")) {
      Canvas.clear();
    }
    // Canvas.clear();
    Object.keys(UsedLayers).forEach((id) => {
      if (typeof (UsedLayers[id]) === "object") {
        UsedLayers[id].remove();
      }
    });
    setUserInfo(null);
    SetLogout(false);
    setOrganizationProjects([]);
    SetMap(null)
    setMapData(null)   // Clear organization projects
    setUserProjects([]);
    SetuTab("1")  // Clear user projects
    SetMapBox(null)
    navigate("/login");

    console.log("user project", setUserProjects);
    console.log('org project', setOrganizationProjects);
  };

  function toggle() {
    setVis(!vis);
    forceUpdate();
  }

  const markLocationAndShowForm = () => {
    let draw = new L.Draw.Marker(map, {
      icon: new customMarker({ type: "survey" }),
      edit: false
    });
    draw.enable();

    // Disable map dragging while drawing
    map.dragging.disable();
    // Listen for touch events for mobile devices
    map.on('touchstart', () => {
      draw.enable();
    });
    map.on(L.Draw.Event.CREATED, (event) => {
      const { lat, lng } = event.layer.getLatLng();
      setMarkedLat(lat);
      setMarkedLng(lng);
      toggleModal();
      // Re-enable map dragging after marking
      map.dragging.enable();
      // Remove touch event listeners
      map.off('touchstart');

      // Allow map dragging only when not drawing
      map.on('mousedown', () => {
        map.dragging.enable();
      });
      map.on('draw:drawstart', () => {
        map.dragging.disable();
      });
    });
  };
  useEffect(() => {
    if (Canvas) {
      if (threeD) {
        Canvas.threeD(true)
      } else {
        Canvas.threeD(false)
      }
    }

  }, [threeD])

  
  const showHelpPopup = () => {
    setModalIsOpen(true);
    console.log("clicked help");
  };


  return (
    <>
      <div className='tool-group' style={{ display: "flex", flexDirection: "row", position: "absolute", left: tools ? "70px" : "355px", top: "8px", zIndex: "900", columnGap: "7px", alignItems: "stretch" }}>
        <button title="Tools" style={{ zIndex: "1000", fontSize: "15px", backgroundColor: 'black', padding: "2px 2px", width: "40px", height: "40px", borderRadius: "50%", border: "none" }} onClick={() => toggle()} className={`btn ${vis ? "btn-dark" : "btn-dark"}`}><i className="fa-solid fa-screwdriver-wrench"></i></button>
        <div className='tools-groups' style={{ display: vis ? "flex" : "none", alignItems: "center", margin: "0px", fontSize: "15px" }}>
          {vis ? (
            <div>
              <span>&#9664;</span>
            </div>
          ) : (<span>&#9654;</span>)}
        </div>
        {window.location.pathname.startsWith("/MapBox") ? (

          <div className={`toolCont`} style={{ width: vis ? "200px" : "0px" }}>
            <div className="tool">
              <button onClick={toggleBuildings} title='3D Building'
                style={{ zIndex: "1000", fontSize: "15px", padding: "2px 2px", width: "38px", height: "38px", borderRadius: "50%", border: 'none' }} className="btn text-white">
                <i className="fa-solid fa-building"></i>
              </button>
            </div>
            {/* <div className="tool">

              <button className="btn btn-dark text-text" onClick={toggleElevationProfile} title='Elevation Profile' style={{ zIndex: "1000", fontSize: "15px", padding: "2px 2px", width: "38px", height: "38px", borderRadius: "50%", border: 'none' }}>
                <i className="fa-solid fa-chart-line"></i>
              </button>
            </div> */}
            {/* <div className="tool">
              <MapboxVectorLayer />
            </div> */}
            <div className="tool">
              <NavLink to="/user-console" title="User Console" style={{ zIndex: "1000", fontSize: "15px", backgroundColor: 'black', padding: "9px 2px", borderRadius: "60%", width: "40px", height: "40px", border: 'none' }} className="btn btn-dark text-text"><i className="fa-solid fa-user"></i></NavLink>
            </div>
            <div className="tool">
              <button title="Logout" style={{ zIndex: "1000", fontSize: "15px", backgroundColor: 'black', padding: "2px 2px", width: "38px", height: "38px", borderRadius: "50%", border: 'none' }} onClick={logout} className="btn-button btn-dark text-danger"><i className="fa-solid fa-power-off"></i></button>
            </div>


          </div>

        ) : (
          <div className={`toolCont`} style={{ width: vis ? "700px" : "0px" }}>
            <div className="tool">
              <Swipe />
            </div>
            <div className="tool">
              <Filter />
            </div>
            <div className="tool">
              <Upload />
            </div>
            <div className="tool">
              <Grid />
            </div>
            <div className="tool">
              <Inspect />
            </div>
            <div className="tool">
              <PixelFilter />
            </div>
            {/* <div className="tool">
              <PixelCalc />
            </div> */}
            <div className="tool">
              <MapCreation />
            </div>
            {/* <div className="tool">
              <SLD />
            </div>
            <div className="tool">
              <Clipper />
            </div> */}
            {/* <div className="tool">
              <GeoRefernce />
            </div>
            <div className="tool">
              <VectorUtils />
            </div>
            <div className="tool">
              <VectorA />
            </div>
            <div className="tool">
              <VectorSplit />
            </div>
            <div className="tool">
              <Conversion />
            </div> */}
            {/* <div className="tool">
              <Documentation />
            </div> */}
            {/* <div className="tool">
                  <NavLink target="self" rel="noopener noreferrer"  to="/MapBox" title="Map Box" style={{ zIndex: "1000", fontSize: "15px", padding: "9px 2px", borderRadius: "60%", width: "40px", height: "40px", border: 'none' }} className="btn btn-dark text-text">3D</NavLink>
                </div> */}


            {userInfo && !userInfo.is_admin && !userInfo.is_superuser && (
              <div className="tool">
                <button
                  title="Survey Form"
                  className="btn text-white survey-button"
                  onClick={() => { setVis(false); markLocationAndShowForm(); if (isMobile) { alert("Click on the map to start survey") } }}
                  style={{ zIndex: "1000", fontSize: "15px", padding: "2px 2px", backgroundColor: 'black', width: "38px", height: "38px", borderRadius: "50%", border: 'none', }}
                >
                  <i className="fas fa-clipboard-check"></i>
                </button>
                {showModal && (
                  <SurveyForm id={userInfo.id} email={userInfo.email_address} toggleModal={toggleModal} initialLat={markedLat} initialLng={markedLng} />
                )}
              </div>
            )}

            <div className="tool">
              <NavLink to="/user-console" title="User Console" style={{ zIndex: "1000", fontSize: "15px", backgroundColor: 'black', padding: "8px 2px", borderRadius: "60%", width: "40px", height: "40px", border: 'none', marginLeft: '5%' }} className="btn btn-dark text-text"><i className="fa-solid fa-user"></i></NavLink>
            </div>
            {/* <div className="tool">
                  <button title="3D Mode"
                      className="btn btn-dark btn-white"
                      id="swipping"
                      onClick={()=>set3d(!threeD)}
                      style={{ zIndex: "1000", fontSize: "15px", padding: "2px 2px", width: "40px", height: "40px", borderRadius: "50%",backgroundColor:threeD?"white":"black",color:threeD?"black":"white" }}
                  >
                      3D
                  </button>
                {threeD ? (
                   <SphericalController/>
                ):(null)}
               
                </div> */}
            {userInfo && userInfo.is_superuser ? (
              <div className="tool">
                <GeoTool />
              </div>) : (null)}
            {/* <div className="tool">
                <div className="toolscont"
            
                >
                  <NavLink to="/pricing" title="Pricing" style={{ zIndex: "1000", fontSize: "15px",backgroundColor:'black', padding: "9px 2px", borderRadius: "60%", width: "40px", height: "40px", border: 'none', }} className="btn btn-dark text-text"><i className="fa-solid fa-tag"></i></NavLink>
                </div>
              
            </div> */}

            <div className="tool">
              <button title="Help and Support" style={{ zIndex: "1000", fontSize: "15px", backgroundColor: 'black', padding: "2px 2px", width: "38px", height: "38px", borderRadius: "50%", border: 'none', }} onClick={showHelpPopup} className="btn-button btn-dark text-white">
                <i className="fa-solid fa-question"></i></button>
            </div>

            <div className="tool">
              <button title="Logout" style={{ zIndex: "1000", fontSize: "15px", backgroundColor: 'black', padding: "2px 2px", width: "38px", height: "38px", borderRadius: "50%", border: 'none', }} onClick={logout} className="btn-button btn-dark text-danger"><i className="fa-solid fa-power-off"></i></button>
            </div>
          </div>
        )}

      </div>

      {modalIsOpen && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            className="col-lg-12 col-sm-2 p-0 custom-modal"
            style={{ zIndex: "1", width: "600px" }}
          >
            {/* <div className="user-modal-header">
              <i className="fa-solid fa-xmark cancel" onClick={() => setModalIsOpen(false)}>
              </i>
            </div> */}
            <div
              style={{ display: "flex", flexDirection: "row", width: "700px" }}
            >
              <div className="p-1"
                style={{
                  flex: "5",
                  color: "white",
                  textAlign: "left",
                  backgroundColor:'#2c3e50',
                  alignContent: "center",
                  textAlign:'center',
                  justifyContent:'center'
                }}
              >
                <img
                src={`${process.env.PUBLIC_URL}/${files}support.png` }
                style={{
                //  border:'2px solid red',
                  width:'200px',
                  height:'300px'
                }}
              />

                {/* <h4 className="mt-4"> Let's Get Connected !</h4>
                <p>Our team is here to help! Reach out to us for support, inquiries, or feedback. </p>

                <br/>

                <p><i class="fa-solid fa-envelope" style={{marginRight:'5px'}}></i> <strong>Email Us : </strong>
                aditya@dharaatech.in</p>
                <br/>

                <p><i class="fa-solid fa-phone" style={{marginRight:'5px'}}></i><strong>Call Us : </strong>
                +91 8262011716</p>
                <br/>

                <p><i class="fa-solid fa-location-dot" style={{marginRight:'5px'}}></i>  <strong>Address : </strong>
                501, level 05, Survey No. 2/1, 411045, Sopan Baug Rd, Archway Society, Balewadi, Pune, Maharashtra 411045</p> */}

              </div>
              <div style={{ flex: "5", padding: "1%" }}>
                <Help setModalIsOpen={setModalIsOpen} />
              </div>
            </div>
          </Modal>
        )}
    </>
  );
}

export default Tools;