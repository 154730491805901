import React from "react";

function Template({children}){

    return(
        <div className="hide-show-container">     
            {children} 
        </div>
          
    )

}

export default Template;